$.extend($.validator.messages, {
  required: "Поле должно быть заполнено",
  remote: "Пожалуйста, введите правильное значение",
  email: "Введите корректную электронную почту",
  url: "Пожалуйста, введите корректный URL",
  date: "Пожалуйста, введите корректную дату",
  dateISO: "Пожалуйста, введите корректную дату в формате ISO",
  number: "Пожалуйста, введите число",
  digits: "Пожалуйста, вводите только цифры",
  creditcard: "Пожалуйста, введите правильный номер кредитной карты",
  equalTo: "Пароли не совпадают",
  extension: "Пожалуйста, выберите файл с правильным расширением",
  maxlength: $.validator.format( "Максимальная длина описания - {0} символов" ),
  minlength: $.validator.format( "Пожалуйста, введите не меньше {0} символов" ),
  rangelength: $.validator.format( "Пожалуйста, введите значение длиной от {0} до {1} символов" ),
  range: $.validator.format( "Пожалуйста, введите число от {0} до {1}" ),
  max: $.validator.format( "Максимальная длина описания - {0} символов" ),
  min: $.validator.format( "Пожалуйста, введите число, большее или равное {0}" ),
});

window.validationInit = function() {
  if ($('.js-validate').length > 0) {
    $('.js-validate').each(function(index, form) {
      $(form).validate({
        ignore: ':hidden',
        errorElement: 'span',
        errorClass: 'label-error',
        highlight: function(element) {
          setTimeout(function() {
            if(!$(element).attr('disabled')){
              $(element).parent().addClass('is-error');

              if($(element).attr('type') == 'checkbox' || $(element).hasClass('js-select2Single') || $(element).attr('type') == 'radio'){
                const parent = $(element).parent();

                parent.append(parent.find('.label-error'));
              }
            }
          });
        },
        unhighlight: function(element) {
          setTimeout(function() {
            if(!$(element).attr('disabled')){
              if($(element).val() != '') $(element).parent().addClass('is-success');
              $(element).parent().removeClass('is-error');
            }
          });
        }
      });

      $('input, textarea, select', $(form)).on('focus change keyup', function() {
        const valid = $(form).validate().checkForm();
        const submit = $(form).find('[type="submit"]');

        setTimeout(function() {
              if(valid){
            submit.prop('disabled', false);
              }
              else {
            submit.prop('disabled', true);
              }
          }, 100);
        });
    });
  }

  $(document).ready(function() {
    $('.js-onlyText').bind("change keyup input click", function() {
     if (this.value.match(/[^а-яА-Яa-zA-Z\s]/g)) {
        this.value = this.value.replace(/[^а-яА-Яa-zA-Z\s]/g, '');
      }
    });
  });

  /*
   * Валидация телефона
   */

  $.validator.addMethod('phonecustom', function(value, element) {
    if(value == '+7 (___) ___-__-__') return true;
    return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/);
  }, 'Указан неверный формат телефона.');

  $.validator.addClassRules('js-phone', {
    phonecustom: true
  });
}

validationInit();