import Swiper from 'swiper/bundle';

if ($('.js-sliderPartners').length > 0) {
  let valLoop = true;
  let sliderPartnersElem = $('.js-sliderPartners');
  const sliderCounterCurrent = sliderPartnersElem.find('.js-sliderCountCurrent');
  const sliderCounterFull = sliderPartnersElem.find('.js-sliderCountFull');
  let partnersCards = $('.partners__card').length;
  let mobilePartnersSlide = Math.round(partnersCards / 2);

  function createWrappper(){
    const create = `
      <div class="js-wrapperProjectsSlide">
      </div>`;
    return create;
  }

  function createSlide(){
    const create = `
      <div class="swiper-slide">
      </div>`;
    return create;
  }

  if (window.innerWidth < 768 && $('.partners__card').length > 0 && $('.partners__card').length > 1) {
    sliderPartnersElem.append(createWrappper());
    $('.js-wrapperProjectsSlide').append($('.partners__card'));

    sliderPartnersElem.find('.swiper-wrapper').html('');

    for (let i = 0; i < mobilePartnersSlide; i++) {
      sliderPartnersElem.find('.swiper-wrapper').append(createSlide());
    }

    sliderPartnersElem.find('.swiper-slide').each(function() {
      let _this = $(this);
      let currentCard = $('.js-wrapperProjectsSlide .partners__card:first-child');
      let nextCard = currentCard.next();

      _this.append(currentCard).append(nextCard);
    });
  }

  sliderCounterFull.text($('.js-sliderPartners .swiper-slide').length);

  if (sliderPartnersElem.find('.swiper-slide').length < 2 ) {
    valLoop = false;
  }

  let sliderPartners = new Swiper('.js-sliderPartners .swiper-container', {
    slidesPerView: 2,
    // slidesOffsetAfter: 16,
    // slidesOffsetBefore: 16,
    speed: 600,
    loop: valLoop,
    navigation: {
      nextEl: '.js-sliderPartners .swiper__next',
      prevEl: '.js-sliderPartners .swiper__prev',
    },

    breakpoints: {
      1200: {
        slidesPerView: 4,
        spaceBetween: 24,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      }
    }
  });

  console.log('work');

  sliderPartners.on('slideChange', function() {
    sliderCounterCurrent.text(this.realIndex + 1);
  });
}