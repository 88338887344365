import Swiper from 'swiper/bundle';

if ($('.js-sliderHistory').length > 0) {
  let valLoop = true;
  let sliderHistory = $('.js-sliderHistory .swiper-container');

  sliderHistory.each(function(index, elem){
    let parent = $(this).parents('.js-sliderHistory'),
        nextBtn = parent.find('.swiper__next').addClass("js-sliderNextBtn-".concat(index)),
        nextBtnStr = ".js-sliderNextBtn-".concat(index),
        prevBtn = parent.find('.swiper__prev').addClass("js-sliderPrevBtn-".concat(index)),
        prevBtnStr = ".js-sliderPrevBtn-".concat(index),
        paginationId = parent.find('.swiper-pagination').addClass("js-sliderPagination-".concat(index)),
        paginationIdStr = ".js-sliderPagination-".concat(index);

      const sliderCounterCurrent = parent.find('.js-sliderCountCurrent');

      if ($(this).find('.swiper-slide').length < 2 ) {
        valLoop = false;
      }

      let sliderHistorySwiper = new Swiper(this, {
        slidesPerView: 1,
        speed: 600,
        loop: valLoop,
        fadeEffect: {
          crossFade: true
        },
        navigation: {
          nextEl: nextBtnStr,
          prevEl: prevBtnStr,
        },
        pagination: {
          el: paginationIdStr,
          type: 'bullets',
          clickable: true
        },
      });

      sliderHistorySwiper.on('slideChange', function() {
        sliderCounterCurrent.text(this.realIndex + 1);
      });
  });
}