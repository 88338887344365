import magnificPopup from 'magnific-popup';

window.modalOpen = function (src, type){
  $.magnificPopup.open({
    items: {
      src: src,
      type: type
    },
    closeBtnInside: true,
    callbacks: {
      beforeOpen: function() {
        $('html').addClass('mfp-open');
      },

      beforeClose: function(){
        $('html').removeClass('mfp-open');
      },
    }
  });
}

$(document).on('click', '.js-modalLink', function(event) {
  const src = $(this).data('mfp-src');
  const type = $(this).data('mfp-ajax') || 'inline';

  event.preventDefault();

  modalOpen(src, type);
});

$(document).on('click', '.js-modalClose', function(event) {
  event.preventDefault();
  $.magnificPopup.close();
});