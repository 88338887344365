import './vendor'

import './components/slider-banner'
import './components/slider-rooms'
import './components/slider-partners'
import './components/validation'
import './components/mask'
import './components/menu'
import './components/slider-room-card'
import './components/slider-history'
import './components/video'
import './components/modal'