import './vendor/babel-polifill.js';
import './vendor/polyfill.min.js';

import svg4everybody from 'svg4everybody'
import $ from 'jquery'

svg4everybody()

window.$ = $
window.jQuery = $

import './vendor/jquery-validation.js'
import './vendor/jquery.inputmask.bundle.min.js'
