import Swiper from 'swiper/bundle';

if ($('.js-sliderRooms').length > 0) {
  let valLoop = true;
  const sliderCounterCurrent = $('.js-sliderRooms .js-sliderCountCurrent');

  if ($('.js-sliderRooms .swiper-slide').length < 2 ) {
    valLoop = false;
  }

  let sliderRooms = new Swiper('.js-sliderRooms .swiper-container', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    slidesOffsetAfter: 16,
    slidesOffsetBefore: 16,
    speed: 600,
    loop: valLoop,
    navigation: {
      nextEl: '.js-sliderRooms .swiper__next',
      prevEl: '.js-sliderRooms .swiper__prev',
    },
    breakpoints: {
      1200: {
        spaceBetween: 24,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      }
    }
  });

  sliderRooms.on('slideChange', function() {
    sliderCounterCurrent.text(this.realIndex + 1);
  });
}