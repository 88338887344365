import Swiper from 'swiper/bundle';

if ($('.js-sliderRoomCard').length > 0) {
  let valLoop = true;
  const sliderCounterCurrent = $('.js-sliderRoomCard .js-sliderCountCurrent');

  if ($('.js-sliderRoomCard .swiper-slide').length < 2 ) {
    valLoop = false;
  }

  let sliderRoomCard = new Swiper('.js-sliderRoomCard .swiper-container', {
    slidesPerView: 1,
    speed: 600,
    loop: valLoop,
    navigation: {
      nextEl: '.js-sliderRoomCard .swiper__next',
      prevEl: '.js-sliderRoomCard .swiper__prev',
    },
  });

  sliderRoomCard.on('slideChange', function() {
    sliderCounterCurrent.text(this.realIndex + 1);
  });
}